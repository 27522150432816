.logo-container {
  z-index: -1;
  // @media (max-width: 1200) {
  //   width: 800px;
  //   height: 609px;
  // }

  width: 800px;
  height: 609px;
  position: absolute;
  @media (max-width: 576px) {
    // bottom: 10%;
    // right: 0vw;
    left: 2vw;
    top: 65vh;
    width: 50vw;
    height: 70vh;
    margin-left: 0;
  }
  bottom: 70%;
  left: 90%;
  top: 0;
  right: 10%;
  margin: auto;
  animation: display-image 4s ease-in-out forwards;
  svg {
    width: 1000px;
    height: 800px;
    @media (max-width: 944px) {
      height: 375px;
      width: 292px;
      // top: 500px;
      // left: 90%;
    }
    bottom: 0;
  }

  .solid-logo {
    // background-size: contain;
    position: absolute;
    top: 48%;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 500px;
    width: 390px;

    @media (max-width: 992px) {
      height: 55vh;
      width: 30vw;
      right: 30vw;
    }
    @media (max-width: 768px) {
      height: 50vh;
      width: 30vw;
      right: 70vw;
    }
    @media (max-width: 576px) {
      height: 30vh;
      width: 45vw;
      right: 0;
      bottom: 0;
      left: -8vw;
      top: -8vh;
      // left: 20vw;
    }

    z-index: 1;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}

@keyframes display-image {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
