.page {
  width: 100%;
  height: 100%;
  position: fixed;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;

  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-name: 1s;
}

.about-page,
.contact-page {
  .text-zone {
    @media (max-width: 576px) {
      left: 20vw;
      width: 60%;
      top: 40%;
      flex-direction: column;
    }
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    h1 {
      font-size: 53px;
      @media (max-width: 576px) {
        font-size: 12vw;
        left: 0;
        margin-bottom: 2vh;
      }
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;

      // &::before {
      //   content: '<h1>';
      //   font-family: 'La Belle Aurore', cursive;
      //   color: #ffd700;
      //   font-size: 18px;
      //   position: absolute;
      //   margin-top: -10px;
      //   left: -10px;
      //   opacity: 0.6;
      //   line-height: 18px;
      // }

      // &:after {
      //   content: '</h1>';
      //   font-family: 'La Belle Aurore', cursive;
      //   color: #ffd700;
      //   font-size: 18px;
      //   line-height: 18px;
      //   position: absolute;
      //   left: -30px;
      //   bottom: -20px;
      //   margin-left: 20px;
      //   opacity: 0.6;
      // }
    }
    p {
      font-size: 15px;
      color: #fff;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
    .project-links {
      color: #ffd700;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 20px;
      margin-right: 75%;

      margin-bottom: 20px;
      float: left;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
