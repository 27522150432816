.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    @media (max-width: 768px) {
      width: 50%;
      margin-left: 2vw;
    }
    @media (max-width: 576px) {
      width: 80%;
      margin-left: 10vw;
      top: 30%;
      // flex-direction: column;
    }
    max-height: 90%;
  }

  h1 {
    color: #fff;
    font-size: 53px;
    @media (max-width: 768px) {
      font-size: 7vw;
    }
    @media (max-width: 576px) {
      font-size: 9vw;
    }
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    // &::before {
    //   content: '<h1>';
    //   font-family: 'La Belle Aurore';
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -40px;
    //   left: 15px;
    //   opacity: 0.6;
    // }

    // &::after {
    //   content: '</h1>';
    //   font-family: 'La Belle Aurore';
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: 18px;
    //   margin-left: 20px;
    //   animation: fadein 1s 1.7s backwards;
    //   opacity: 0.6;
    // }

    // img {
    //   width: 40px;
    //   height: 43px;
    //   margin-left: 10px;
    //   opacity: 0;
    //   height: auto;
    //   animation: rotateIn 1s linear both;
    //   animation-delay: 1.4s;
    //   // background-size: contain;
    // }
  }

  h2 {
    color: #ffd700;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    @media (max-width: 576px) {
      font-size: 3vw;
    }
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffd700;
    @media (max-width: 768px) {
      font-size: 2vw;
    }
    @media (max-width: 576px) {
      padding: 2vw 2vw;
    }
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    margin-right: 20px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}
